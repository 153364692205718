import React from 'react';

const Home = () => {
  return (
    <div style={styles.container}>
      <div style={styles.section}>
        <h1 style={styles.title}>Welcome to Capital Pinball Parlor!</h1>

        <div style={styles.hoursSection}>
          <h2 style={styles.header}>Hours:</h2>
          <p style={styles.hours}>Sunday: noon - 10pm</p>
          <p style={styles.hours}>Monday: closed</p>
          <p style={styles.hours}>Tuesday: closed</p>
          <p style={styles.hours}>Wednesday: noon - 10pm</p>
          <p style={styles.hours}>Thursday: noon - 10pm</p>
          <p style={styles.hours}>Friday: noon - 11pm</p>
          <p style={styles.hours}>Saturday: noon - 11pm</p>
        </div>

        <div style={styles.pricingSection}>
          <h2 style={styles.header}>Pricing:</h2>
          <p style={styles.pricing}>$5 per half hour</p>
          <p style={styles.pricing}>$15 - all day</p>
          <p style={styles.pricing}>$10 - all day kids & seniors</p>
        </div>

        <p style={styles.description}>
          Capital Pinball Parlor is Sacramento's premier pinball arcade.
        </p>
        <p style={styles.description}>
          Your ultimate pinball experience starts here.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#1a1a1a',
    padding: '20px',
  },
  section: {
    backgroundColor: '#333',
    color: '#f1f1f1',
    padding: '40px',
    textAlign: 'center',
    maxWidth: '600px',
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#ffeb3b',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 'normal',
    marginBottom: '30px',
    color: '#f1f1f1',
  },
  header: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '15px',
    color: '#ffeb3b',
  },
  hoursSection: {
    marginBottom: '30px',
  },
  hours: {
    fontSize: '18px',
    marginBottom: '8px',
  },
  pricingSection: {
    marginBottom: '30px',
  },
  pricing: {
    fontSize: '18px',
    marginBottom: '8px',
  },
  description: {
    fontSize: '16px',
    fontWeight: '300',
    marginTop: '20px',
    lineHeight: '1.5',
  },
};

export default Home;
